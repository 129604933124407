@import './color.css';


.primaryButton{
    border-radius: 32px;
    background: var(--bizcrunch-gradient);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;
    height: 40px;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 16px;

}


.primaryButton:hover{
    background: var(--bizcrunch-gradient-hover);
}


.primaryButton:focus{
    box-shadow: 0px 0px 0px 4px var(--primary-100, #FFE4E8), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.primaryButton:disabled{
    background: var(--primary-200, #FECCD6);
}


.primaryButton.search{
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.secondaryButton{
    display: inline-flex;
    height: 40px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: row;

    border-radius: 32px;
    border: 1px solid var(--gray-300);
    background: var(--base-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.secondaryButton:hover{
    background: var(--gray-50);
}

.secondaryButton:focus{
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.secondaryButton:disabled{
    color: var(--gray-300);
}



.secondaryButtonIcon{
    width: 16px;
    height: 16px;
}

.applyFilterBtn{
    width: 130px;
}





.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 159px;
    width: fit-content;
    /* width: 250px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext.side {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 500%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext.header {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 150%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }
  
  .tooltip .tooltiptext.radioTT {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 200%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext.button {
    bottom: 120%;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }


  .tooltipInner {
    position: relative;
    display: inline-block;
  }
  
  .tooltipInner .tooltipInnertext {
    visibility: hidden;
    min-width: 159px;
    width: fit-content;
    /* width: 250px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltipInner .tooltipInnertext.side {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 500%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }
  
  .tooltipInner .tooltipInnertext.radioTT {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 200%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltipInner .tooltipInnertext.button {
    bottom: 120%;
  }
  
  .tooltipInner:hover .tooltipInnertext {
    visibility: visible;
    opacity: 1;
  }


  .closeButton{
    padding: 10px;
    height: 44px;
    width: 44px;
  }

  .closeButtonExport{
    height: 24px;
    width: 24px;
  }

  .closeButton:hover{
    border-radius: var(--rounded, 32px);
    background: var(--gray-50, #F9FAFB);
  }

  .closeButton:focus{
    border-radius: var(--rounded, 32px);
    background: var(--gray-50, #F9FAFB);
  }