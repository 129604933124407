@import "./constants/color.css";

.billingTilesRow {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.currentPlanTile {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 32px;
  background: var(--gray-50, #f9fafb);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.currentPlanTileSkeleton {
  width: 100%;
  border-radius: 32px;

  flex-direction: column;
  align-items: flex-start;
}

.cptTopRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.cptTopRow.centre {
  align-items: center;
}

.cptTitleColumn {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.cptTitleRow {
  display: flex;
  gap: 8px;
  align-items: center;
}

.cptPlanBubble {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--primary-200, #feccd6);
  background: var(--primary-50, #fff1f3);
  mix-blend-mode: multiply;
}

.cptMonthlyPrice {
  display: flex;
  gap: 2px;
  align-items: flex-end;
}
.cptMonthlyPriceLeft {
  display: flex;
  gap: 2px;
  align-items: center;
}

.cptCreditSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: var(--base-white, #fff);
}

.cptProgress {
  width: 100%;
  height: 8px;
  background: var(--gray-200);
  border-radius: 4px;
}
.cptProgress::-moz-progress-bar {
  background: var(--bizcrunch-gradient);
  border-radius: 4px;
}
.cptProgress::-webkit-progress-bar {
  background: transparent;
}
.cptProgress::-webkit-progress-value {
  background: var(--bizcrunch-gradient);
  border-radius: 4px;
}

.cptCardSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--base-white, #fff);
}

.cptCardSectionLeft {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.cptCardProviderImg {
  width: 58px;
  height: 40px;
  /* border: 2px solid var(--gray-100, #F2F4F7); */
  border-radius: 12px;
}

.cptCardDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cptCardEmail {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.billingInvoices {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 50px;
}

.billingDownloadOptions {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.billingRowStatus {
  width: fit-content;
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--success-200, #abefc6);
  background: var(--success-50, #ecfdf3);
  mix-blend-mode: multiply;
}

.billingTable {
  z-index: 200;
  overflow-y: auto;
}

.billingTable td {
  min-width: 150px;
  padding: 0px;
  /* background-color: #E31B53; */
}

.billingTable th {
  padding: 0px;
}

.popUp.updatePayment {
  width: 480px;
}

.popUp.editPlan {
  width: 640px;
  gap: 24px;
}

.popUp.cancelPlan {
  width: 400px;
  padding: 24px;
}

.popUp.pausePlan {
  width: 700px;
  padding: 24px;
}

.popUp.unpausePlan {
  width: 500px;
  padding: 24px;
}

.popUp.choosePlan {
  width: 980px;
}

.popUp.confirmPlan {
  width: 480px;
  padding: 24px;
  gap: 30px;
}

.popUp.creditBreakdown {
  width: 254px;
  height: auto;
  padding: 24px;
  gap: 10px;

  position: absolute;
}

.greyLine {
  width: 100%;
  height: 1px;
  background-color: var(--gray-200);
}

.frequencyPicker {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  background: var(--gray-50, #f9fafb);
}

.planFreq {
  padding: 8px 12px;
}

.planFreq.selected {
  border-radius: 120px;
  background: var(--base-white, #fff);

  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.planTile {
  width: 297px;
  display: flex;
  flex-direction: column;
  gap: -20px;
  min-height: 333px;

  border-radius: var(--rounded, 32px);
  border: 2px solid var(--gray-50, #f9fafb);
  background: var(--gray-50, #f9fafb);
  cursor: pointer;
}

.planTile.existing {
  border: 2px solid var(--primary-100, #ffe4e8);
  background: var(--base-white, #fff);
}

.planTile.selected {
  border: 2px solid var(--primary-600, #e31b53);
  background: var(--base-white, #fff);
}

.skeleton {
  height: 100%;
  border-radius: 50%;
}

.confirmPayment {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
}

.disabledCancelPlan {
  cursor: auto;
  margin-top: -20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--gray-50);
}
