
.filterRow{
    display: flex;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    margin-bottom: 10px;
}

.filterRow.marginTop{
    margin-top: 30px;
}
.numberInput{
    width: 100px;
    background-color: #eeea;
    padding: 5px;
}

.numberInput.date{
    width: 150px;
}

.rangeFilter{
    display: flex;
    flex-direction: column;
}


.numberInput.sic{
    width: 450px;
}

.multipleFilter{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.multipleFilter.collapsible{
    margin-top: -10px;
}


.textFilter{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.textFilterInput{
    width: 100%;
    background-color: #eeea;
    padding: 5px;
}


.rangeFilterInput{
    height: 40px;
    width: 175px;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 16px;
    color: #2E253F;


    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.radioFilter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    gap: 10px;
}

.radioFilter.location{
    margin-left: 5px;
}

.radioRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


.sicFilter{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    overflow-y: hidden;
    padding: 3px;
}

.sicRow{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
}

.sicInnerRow{
    
    display: flex;
    align-items: center;
    gap: 4px;
}

.sicIndent{
    width: 10px;
}

.sicArrow{
    height: 16px;
}

.sicOptions{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 20px;
    width: 100%;
}



.compTypeTitle{
    margin-top: 33.5px;
    margin-bottom: 23.5px;
    margin-left: 8px;
}













.rangeFilter{
    max-width: 100%;
}


.container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
  
  .slider {
    position: relative;
    width: 320px;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 8px;
  }
  
  .slider__track {
    background-color: #EAECF0;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range {
    background: var(--bizcrunch-gradient);
    z-index: 2;
  }
  
  .slider__left-value,
  .slider__right-value {
    color: #101828;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 20px;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: relative;
    height: 0;
    width: 320px;
    outline: none;
  }
  
  .thumb--left {
    z-index: 3;
    top: 8px;
  }
  
  .thumb--right {
    z-index: 4;
    bottom: 18px;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    border: 1.5px solid var(--primary-600, #FF2A6F);
    background: var(--base-white, #FFF);
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    border: 1.5px solid var(--primary-600, #FF2A6F);
    background: var(--base-white, #FFF);
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  







.pac-container
{
    padding: 9px 10px;
    border-color: transparent;
    

}

.pac-item{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-900, #101828);
}

.pac-item-query{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-900, #101828);
}


.pac-item:hover{    
    font-weight: 500;
}

.pac-item-query:hover{
    font-weight: 500;
}


   
.pac-item:hover .pac-container{
    border-radius: 6px;
    background: var(--gray-50, #F9FAFB);
    font-weight: 500;
    color: var(--primary-600, #E31B53);
}


.pac-item .pac-icon{
    background: url(../../public/assets/mapMarker.svg) no-repeat;
    background-size: contain;
}
  
.pac-item:hover .pac-icon{
    background-image: var(--map-marker-hover);
    background-size: contain;
}


.pac-item-selected .pac-icon{
    background-image: var(--map-marker-hover);
    background-size: contain no-repeat;
}


