@import './color.css';


/* .checkbox{
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background: var(--base-white);
}

.checkbox:hover{
    border: 1px solid red;
    background: var(--primary-100);
}

.checkbox:focus{
    
    border: 1px solid var(--primary-300, #FEA3B4);
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #FFE4E8;
}

.checkbox:disabled{
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background: var(--gray-100);
}

.checkbox:checked{
    background-color:  #FFE4E8;
    
    border: 1px solid var(--primary-600, #E31B53);

    stroke: var(--primary-600, #E31B53);
} */





.checkbox:checked + label::after {
    content: '';
    position: absolute;
    width: 1.2ex;
    height: 0.4ex;
    top: 1.05ex;
    left: 0.4ex;
    border: 2px solid var(--primary-600);
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
 }

 .checkbox.billing:checked + label::after {
    top: .5ex;
 }
 
 
 .checkbox {
     position: absolute;
     left: -999em;
 }
 
 .checkbox + label {
     position: relative;
     overflow: hidden;
     cursor: pointer;
 }

 
 
 .checkbox + label::before {
    content: "";
    display: inline-block;
    height: 2ex;
    width: 2ex;
    border: 1px solid var(--gray-300);
    background: var(--base-white);
    border-radius: 4px;
    margin-right: 0.5em;
 }

 
.checkbox:checked + label::before{
    background-color:  var(--primary-100, #FFE4E8);
    border: 1px solid var(--primary-600, #E31B53);
    stroke: var(--primary-600, #E31B53);
}

.checkbox:hover + label::before{
    border: 1px solid var(--primary-600, #E31B53);
    background: var(--primary-100);
}

 .checkboxLabel{
    font-size:18px;
 }



 .checkbox:disabled + label::before{
  /* cursor: auto; */
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background: var(--gray-100);
}
/* 
.checkbox:disabled{
  cursor: auto;
}
*/






.input-sm{
    height: 16px;
    width: 16px;
}

.input-md{
    height: 20px;
    width: 20px;
}





input[type='radio'] {
    -webkit-appearance:none;
    appearance:none;
}

input[type='checkbox'] {
    -webkit-appearance:none;
    appearance:none;
}


input[type='text'] {
    -webkit-appearance:none;
    appearance:none;
}



.radio{
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid var(--gray-300);
    background: var(--base-white);
}

.radio:hover{
    border-radius: 50%;
    border: 1px solid var(--primary-600, #E31B53);
    background: var(--primary-100, #FFE4E8);
}

.radio:focus{
    
    border-radius: 100px;
    border: 1px solid var(--primary-300, #FEA3B4);
    background: var(--base-white, #FFF);

    /* Focus ring/4px primary-100 */
    box-shadow: 0px 0px 0px 4px var(--primary-100, #FFE4E8);
}

.radio:disabled{
    border-radius: 50%;
    border: 1px solid var(--gray-300);
    background: var(--gray-100);
}

.radio:checked{
    border-radius: 50%;
    fill: var(--primary-50, #FFF1F3);
    border: 1px solid var(--primary-600, #E31B53);
    accent-color: var(--primary-600, #E31B53);
}

.radio:before {
    content:'';
    display:block;
    width:40%;
    height:40%;
    margin: 30% auto;    
    border-radius:50%;    
  }

  .radio:checked:before {
    background:var(--primary-600, #E31B53);
  }












  .textInput{
    width: 100%;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .textInput.date{
    width: 150px;
  }
  .textInput.date{
    width: 150px;
  }

  .textInput::placeholder{
    color: var(--gray-500, #667085);
  }

  .textInput:focus{
    outline: none;

    border-color: transparent;
    border: 1px solid var(--primary-300, #FEA3B4);
    background: var(--base-white, #FFF);

    /* Shadow/xs focused 4px primary-100 */
    box-shadow: 0px 0px 0px 4px var(--primary-100, #FFE4E8), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .textInput.focus{
    outline: none;

    border-color: transparent;
    border: 1px solid var(--primary-300, #FEA3B4);
    background: var(--base-white, #FFF);

    /* Shadow/xs focused 4px primary-100 */
    box-shadow: 0px 0px 0px 4px var(--primary-100, #FFE4E8), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .textInput:disabled{
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--gray-50, #F9FAFB);
    color: var(--gray-500, #667085);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .textInput.search{
    background: url("/public/assets/search-lg.png") no-repeat left;
    background-size: 20px;
    background-position: 10px;
    padding-left: 40px;
  }

  

  
  .textInput.error{
    background: url("/public/assets/inputError.png") no-repeat right;
    background-size: 20px;
    background-position: 95%;
    border: 1px solid var(--error-300, #FDA29B);
  }


  
  .textInput.currency{
    background: url("/public/assets/gbp.png") no-repeat left;
    background-size: 8.5px;
    background-position: 10px;
    padding-left: 21px;
    padding-bottom: 9.5px
  }

  
  .textInput.currency.regular{
    background: url("/public/assets/gbp-grey.png") no-repeat left;
    background-size: 8.5px;
    background-position: 10px;
    padding-left: 21px;
  }


  .textInput.inner{
    padding: 8px;
    border: transparent; 
  }

  .textInput.inner:focus{
    border: transparent;
    box-shadow: none;
  }


.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderSwitch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: var(--gray-300);
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderSwitch:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0px 0.6px 1.8px 0px rgba(16, 24, 40, 0.1);
}

input:checked + .sliderSwitch {
  background-color: var(--primary-600);
}

input:checked + .sliderSwitch:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
