.savedFiltersScreen{
    margin-top: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    z-index: 200;
    padding-bottom: 100px;
}

.sfTitles{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);

}



.popUp{ 
    padding: 27px;
    border-radius: 32px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    cursor: auto;
    gap: 10px;
    z-index: 200;
}


.popUp.header{
    position: absolute;
    width: 240px;
    height: auto;
    top: 50px;
    right: 0px;
    padding: 0px;
    z-index: 205;
}


.popUp.saveFilter{
    width: 640px;
    display: flex;
    flex-direction: column;
    gap: 16px;
} 

.popUp.export{
    width: 856px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow: hidden;
    padding: 0px;
    z-index: 100;
}


.popUp.editFilter{
    width: 640px;
    min-height: 440px;
    display: flex;
    flex-direction: column;
    gap: 16px;
} 

.popUp.exportAgain{
    padding: 24px;
}

.popUp.notEnoughCredits{
    width: 450px;
    height: fit-content;
}

.popUp.loading{
    height: 120px;
    width: 120px;
    padding: 0px;
    overflow: hidden;
}


.exportRight{
    padding: 30px;
    background-color: #F9FAFB;
    width: 364px;
    gap: 24px;
}

.exportLine{
    height: 1px;
    width: 100%;
    background-color: #EAECF0;
    margin-top: 20px;
    margin-bottom: 13px;
}

.exportBuyMore{
    background-color: white;
    width: 100%;
    padding: 12px 16px;
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.sfpuTopRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sfpuIcon{
    height: 48px;
    width: 48px;
    padding: 10px;
}

.sfpuMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.sfpuMain.inner{
    gap: 4px;
}
.sfpuMain.inner2{
    gap: 12px;
}
.sfpuMain.inner3{
    gap: 4px;
    margin-bottom: 30px;
    margin-top: -10px;
}

.sfpuButtons{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 16px;
}

.efpuGrid{
    flex: 100%;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 11px;
    row-gap: 6px;
}

/* ------------------------------------------------------------------- */


.savedFilterRow{
    width: 100%;
    padding: 16px 16px 16px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-200);
    gap: 20px;
    position: relative;
}

.sfrTitle{
    gap: 12px;
    width: 270px;
    display: flex;
    align-items: center;
}

.sfrTitleIcon{
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background: var(--gray-50, #F9FAFB);
}

.sfrTitleColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sfrTitleColumn.time{
    gap: 2px;
    min-width: 190px;
}

.sfrFilters{
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    gap: 12px;
}

.sfrFilters.item{
    max-width: 128px;
}

.sfrItemIcon{
    width: 20px;
    height: 20px;
}

.sfrItemText{
    display: block;
    max-lines: 1;

    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sfrDots{
    width: 40px;
    height: 40px;
}


.sfrUpdatesRow{
    display: flex;
    align-items: center;
    gap: 8px;
}

.sfrUpdateBubble{
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--primary-200, #FECCD6);
    background: var(--primary-50, #FFF1F3);
    mix-blend-mode: multiply;
    gap: 4px;
}

.sfrUpdateBubble.blue{
    border: 1px solid var(--blue-200, #B2DDFF);
    background: var(--blue-50, #EFF8FF);
}

.seeAllFilters{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.safRightArrow{
    width: 12px;
    height: 12px;
}

.sftRow{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 10px;
}

.sftRow:hover{
    background-color: var(--gray-50);
}


.sftIcon{
    height: 16px;
    width: 16px;
}

/* ------------------------------------------------------------------- */

.sepuButtonsLeft{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 250px;
    text-align: left;
}

.sepuButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sepuGrid{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 5px;
}

.sepuGridRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.exportGrid{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
    row-gap: 18px;
}


/* ------------------------------------------------------------------- */

.exportOptionTile{
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 17px;
    border-radius: 12px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);
}

.exportOptionTile.selected{
    gap: 16px;
    background: var(--primary-50, #FFF1F3);
    border: 2px solid var(--primary-600, #E31B53);
}

.eotIconDiv{
    display: flex;
    padding: 4px;

    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 4px solid var(--primary-50, #FFF1F3);
    background: var(--primary-100, #FFE4E8);
    mix-blend-mode: multiply;
}

.eotIcon{
    width: 24px;
    height: 24px;
}

.eotText{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.eotCheck{
    width: 19px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
}

.eotCheck.plan{
    height: 20px;
    width: 20px;
}


.eotCheck.selected{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-600, #E31B53);
    background: var(--primary-600, #E31B53);
}

.eotCheckIcon{
    width: 10px;
    height: 10px;
    margin-top: 1px;
}


/* ------------------------------------------------------------------- */


.sharedExportScreen{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding-top: 132px;
}

.sharedExportTile{
    display: flex;
    width: 640px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: var(--Rounded, 32px);
    background: var(--gray-50, #F9FAFB);    
}