.row{
    display: flex;
}

.column{
    display: flex;
    flex-direction: column;
}

.jc-centre{
    justify-content: center;
}

.jc-start{
    justify-content: flex-start;
}

.jc-end{
    justify-content: flex-end;
}

.jc-between{
    justify-content: space-between;
}



.ai-centre{
    align-items: center;
}

.ai-start{
    align-items: flex-start;
}

.ai-end{
    align-items: flex-end;
}

.ai-between{
    align-items: space-between;
}

.ai-stretch{
    align-items: stretch;
}


.width150{
    width: 150%;
}

.height100{
    height: 100%;
}

.width100{
    width: 100%;
}

.width75{
    width: 75%;
}

.width50{
    width: 50%;
}


.width40{
    width: 40%;
}

.width48px{
    width: 48px
}


.width100px{
    width: 100px
}

.width500px{
    width: 500px;
    
}

.width1000px{
    width: 1000px
}

.widthMaxContent{
    width: max-content;
}

.maxWidth200{
    max-width: 200px;
}


.maxWidth300{
    max-width: 300px;
}

.height100VH{
    height: 100vh;
}

.minHeight30{
    min-height: 30px;
}

.flex1{
    flex-grow: 1;
}

.gap4{
    gap: 4px;
}

.gap6{
    gap: 6px;
}

.gap8{
    gap: 8px;
}

.gap12{
    gap: 12px;
}

.gap16{
    gap: 16px;
}

.gap20{
    gap: 20px;
}

.gap24{
    gap: 24px;
}


.gap32{
    gap: 32px;
}

.gap40{
    gap: 32px;
}

.gap57{
    gap: 57px;
}


.gap64{
    gap: 64px;
}


.paddingSides8{
    padding-left: 8px;
    padding-right: 8px;
}

.paddingSides16{
    padding-left: 16px;
    padding-right: 16px;
}


.padding10{
    padding: 10px;
}


.padding20{
    padding: 20px;
}
.padding24{
    padding: 24px;
}

.padding50{
    padding: 50px;
}

.padding70{
    padding: 70px;
}

.padding3{
    padding: 3px;
}

.paddingRight20{
    padding-right: 20px;
}

.paddingBottom8{
    padding-bottom: 8px;
}

.marginTop20{
    margin-top: 20px;
}


.marginTop200{
    margin-top: 200px;
}


.marginBottom20{
    margin-bottom: 20px;
}


.br32{
    border-radius: 32px;
}

.overflowY{
    overflow-y: auto;
}

.topBorder{
    border-top: 1px solid var(--gray-200);
}

.borderBox{
    box-sizing: border-box;
}


.lightGrey{
    background-color: #F9FAFB;
}

.red{
    background-color: red;
}

.blue{
    background-color: blue;
}

.pointer{
    cursor: pointer;
}
.cursorDefault{
    cursor: default;
}


.greyLine{
    height: 1px;
    width: 100%;
    background-color: var(--gray-200);
}

.hover:hover{
    background-color: var(--gray-50);
}


.z200{
    position: relative;
    z-index: 200;
}

.z400{
    position: relative;
    z-index: 400;
}

.flexGrow{
    flex-grow: 1;
}
.relative{
    position: relative;
}