@import './constants/color.css';

.header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: lightsalmon; */
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    position: absolute;
    width: 100%;
    z-index: 205;
    box-sizing: border-box;
}

.logoImg{
    /* min-height: 17px;
    height: 17px; */
    max-width: 120px;
    cursor: pointer;
    margin-right: 14px;
}

.headerLoggedIn{
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.headerCredits{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: var(--gray-50, #F9FAFB);
    padding: 16px;
    font-weight: 700;
    font-size: 12px;
}

.headerImage{
    height: 40px;
}

.headerName{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bizcrunch-gradient);
    color: white;
    font-weight: 700;

}


.headerLeftSection{
    display: flex;
    align-items: center;
    gap: 4px;
}

.headerLink{
    padding: 8px 12px;
    cursor: pointer;
}

.headerLink.selected{
    border-radius: 40px;
    background: var(--gray-900, #101828);  
    color: var(--base-white); 
}