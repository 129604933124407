
.pricingToggle{
    display: flex;
    border-radius: 32px;
    background-color: #F2F4F7;
    padding: 3px 0px;
    box-sizing: border-box;
    overflow: hidden;
}

.pricingToggleOption{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 206px;
    cursor: pointer;
    color: #667085;
    padding: 7px;;
}

.pricingToggleOption.selected{
    border-radius: 32px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px #0000001A;
    box-shadow: 0px 2px 6px 0px #0000001A;
    color: #101828;
}

.pricingToggleDiscount{
    height: 21px;
    padding: 6px;
    color: #fff;
    border-radius: 32px;    
    background-color: var(--primary-500, #F63D68);
}

.pricingTile{
    width: 1000px;
    min-height: 100px;
    height: auto;
    background-color: white;
    border: 1px solid #EFEFEF;
    border-radius: 32px;
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;

}


.pricingTile.iframe{
  min-height: 500px;
}


.pricingExample{
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 12px 18px;
    text-align: left;
}


.pricingSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 350px;
    height: 8px;
    border-radius: 4px;
    background: #F2F4F7;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    accent-color: var(--primary-600, #E31B53);
  }
  
  .pricingSlider:hover {
    opacity: 1;
  }
  
  .pricingSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    border: 1.5px solid var(--primary-600, #E31B53);
    cursor: pointer;
  }
  
  .pricingSlider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: white;
    border: 1.5px solid var(--primary-600, #E31B53);
    cursor: pointer;
  }


  .backGreyCircle{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #F9FAFB;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .greyHorizontal{
    width: 100%;
    height: 1px;
    background-color: #EAECF0    ;
  }

  .creditsBkg{
   
    padding: 7px 16px;
    border-radius: 32px;
    background-color: #F9FAFB;
  }