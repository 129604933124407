.loginScreen
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    min-height: 100vh;
    z-index: 10;
    /* padding-top: 151px; */
}

.loginScreen.iframe{
    min-height: auto;
}



@media (min-height:800px) {
    .loginScreen{padding-bottom: 10%;}
}


.loginIcon{
    width: 48px;
    height: 48px;
}


.loginTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

.loginInputs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    max-width: 300px;
    width: 100%;
    text-align: left;
}

.loginInput{
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* align-items: flex-start; */
    width: 100%;
}

.loginTextInput{
    height: 40px;
    width: 300px;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 16px;
    color: #2E253F;


    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.loginForgotRow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.loginForgotRow.margin24{
    margin-top: 24px;
}

.loginBtn{
    width: 100%;
    margin-top: 8px;
}

.loginTerms{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.loginTermsRow{
    display: flex;
    gap: 5px;
}