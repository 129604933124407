@import './constants/color.css';

.disclaimer{
    display: none;
    margin-top: -100px;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #F3F3F5;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px #F3F3F5;

}

.searchbar{
    width:1010px;
    height: 72px;
    border-radius: 120px;
    border: 1px solid #F3F3F5;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px #F3F3F5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 18px;
}

.searchbar.floating{
    position: fixed;
    z-index: 20;
    box-shadow: 0px 2px 20px 0px var(--gray-300);
}

.searchbar.floating.showExport{
    z-index: 25;
}

.searchbar.selected{
    background: #F7F8F8;
}

.searchbarBasicFilters{
    display: flex;
    align-items: center;
    height: 100%;
}

.searchbarLine{
    width: 1px;
    height: 38px;
    background: #DEDEDE;
}

.searchbarLine.hide{
    background: transparent;
}

.searchbarBF{
    width: 170px;
    height: 100%;
    position: relative;
}

.searchbarBF.selected
{
    background-color: white;
    border-radius: 120px;
    box-shadow: 0px 2px 20px 0px #F3F3F5;
    z-index: 20;
}

.searchbarBFInner{
    padding-top: 16px;
    padding-left: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    cursor: pointer;
}



.sbfSubText{
    width: 90%;
    max-lines: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    /* height: 20px; */
}

.searchbarRight{
    display: flex;
    justify-content: space-between;
    width:100%;
    margin-right: 5px;
}
.searchBarRightRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.searchbarAdvanceFilters{
    position: relative;
}


.searchbarAdvanceFiltersInner{
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 9.46px;
    width: 91px;
    cursor: pointer;
}

.searchbarAdvanceFilters.selected{
    background-color: white;
    
    border-radius: 120px;
    box-shadow: 0px 2px 20px 0px #F3F3F5;
}

.searchbarFilterImg{
    height: 12px;
}

.searchbarSAFrow{
    display: flex;
    align-items: center;
    gap: 4px;
}
.searchbarSAFicon{
    height: 12px;
    width: 12px;
}

.clearAllBtn{
    width: 78px;
    height: 60px;
    border-radius: 120px;
    background: var(--gray-50, #F9FAFB);
}

.clearAllBtn.selected{
    background: var(--gray-100, #F2F4F7);
}

.searchBarBtn{
    width: 60px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchBarBtn.selected{
    width: 127px;
    border-radius: 120px;

}

.searchBarBtnTopRow{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.searchIcon{
    width: 24px;
    height: 24px;
}

.searchIcon.small{
    width: 14px;
    height: 14px;    
}


.searchBubble{
    position: absolute;
    top: 88px;
    left: 0;
    
    padding: 27px;
    border-radius: 32px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    cursor: auto;
    gap: 10px;
    z-index: 200;
}


.searchBubble.location{
    width: 466px;
    height: auto;
    display: flex;
    align-items: flex-start;
}


.searchBubble.industry{
    width: 466px;
    /* height: 423px; */
    overflow-y: hidden;
    display: flex;
    align-items: flex-start;
}

.searchBubble.industry.admin{
    position: relative;
    top: 0px;
}


.searchBubble.revenue{
    width: 402px;
    height: 270px;
}


.searchBubble.smartAcquire{
    width: 519px;
    height: 301px;
    left:auto;
    right:-170px
}


.searchBubble.advancedSearch{
    width: 1010px;
    height: 500px;
    left:auto;
    right:-235px
}

.searchBubble.advancedSearch.upgraded{
    width: 310px;
    height: auto;
    padding: 24px;
    top: 88px;
}



.searchBubbleTitleRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.searchBubble.savedFilterTool{
    width: 240px;
    height: 92px;
    left:auto;
    right: -230px;
    top: 0px;
    padding: 6px;
    align-items: flex-start;
    gap: 0px;
}

.searchBubble.savedFilterTool.export
{
    height: fit-content;
}





.searchBubbleTitleSec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 100%;

    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    /* font-family: Inter; */
}



.tutorialBubble{
    position: absolute;
    width: 320px;
    top: 88px;
    left: 0;
    
    padding: 8px;
    border-radius: 24px;
    background: var(--bizcrunch-gradient);    
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    z-index: 301;

    color: var(--Base-White, #FFF);
}

.tutorialBubble.resultRow{
    left: 350px;
    top:-90px
}


.tutorialBubble.export{
    left: -120px;
    top:50px
}

.tbImg{
    border-radius: 16px;
    background: var(--Gray-100, #F2F4F7);
    height: 160px;
    width: 100%;
    object-fit: cover;
    object-position: 0px -30px;
}

.tbImg.more{
    object-position: 0px -0px;

}

.tbButton{
    display: flex;
    height: 40px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Rounded, 32px);
    background: rgba(255, 255, 255, 0.30);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.tutorialTint{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: var(--Gray-900, #101828);
    z-index: 300;
}



.smartAcquireFilter
{
    width: 214px;
    height: 40px;
    border-radius: 32px;
    border: 1px solid #EEE;
    background: #FFF;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;

    cursor: pointer;
    margin-top: 8px;
}

.smartAcquireFilter.selected{
    background: var(--bizcrunch-gradient);
}

.smartAcquireFilter.pink{
    width: 178px;
    background: linear-gradient(23deg, rgba(206, 2, 91, 0.15) 0%, rgba(255, 0, 138, 0.15) 50.52%, rgba(255, 84, 84, 0.15) 100%);
}

.safGrid{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 8px;
    
}

.safLeft{
    display: flex;
    align-items: center;
    gap: 5px;
}

.safPlus{
    height: 12px;
    width: 12px;
}

.safTitle{
    margin-left: 9px;
    margin-right: 3px;
}

.safTitle.selected{
    color: white;
}

.safInfo{
    height: 16px;
    width: 16px;
}

.safIcon{
    height: 16px;
    width: 16px;
    margin-right: 8px;
}



.revenueInputRow{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 100%;

    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;

    margin-top: 12px;
    margin-bottom: 12px;
}


.revenueApply{
    color: #D70471;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
    margin-left: 12px;
    cursor: pointer;
}

.revenueChecked{
    height: 20px;
    width: 20px;
    accent-color: #D70471;

}

.revEstIcon{
    width: 16px;
    height: 16px;
    margin-right: -8px;
}

/* -------------------------------------------------------------------------------- */



.advancedSearch{
    display: flex;
    flex-direction: row;
    padding: 0px;
}

.leftSectionAS{
    padding-left: 27px;
    padding-top: 27px;
    padding-bottom: 27px;

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    width: 23.3%;

    overflow-y: auto;
}

.mainSectionAS{
    position: relative;
    flex: auto;
    padding: 27px;

    border-color: #F2F2F2;
    border-right-style: solid;
    border-left-style: solid;
    border-top-style: none;
    border-bottom-style: none;
    border-width: 2px;
    width: 48.4%;
}

.rightSectionAS{
    padding: 27px 0px 27px 0px;
    display: flex;
    width:25.3%;

    flex-direction: column;
    gap: 18px;
}


.noFilters{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.appliedFiltersColumn{
    display: flex;
    height: 100%;
    width: 100%;
    
    flex-direction: column;
    /* align-items: stretch; */
    gap: 10px;
    font-size: 13px;
    text-align: left;

    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-right: 20px;
}











.filterOption{
    height: 40px;
    padding: 10px 0px 10px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-size: 13px;
}

.filterOption.open{
    height: auto;
    border-radius: 12px;
    background: var(--gray-50);
}

.foIcon{
    height: 16px;
    width: 16px;
}

.foTitle{
    display: flex;
    flex-direction: row;
    gap: 11px;
    align-items: center;
    cursor: pointer;
}

.foOptions{
    padding-left: 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}




.foList{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    height: 100%;
}

.foListTitle{margin-bottom: 20px;}


.collapsibleFilter{
    width: 100%;
    height: auto;
    padding: 10px 8px 10px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
 
    border-color: #F2F2F2;
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: solid;
    border-width: 2px;

}

.collapsibleFilter.open{
    height: auto;
}


.collapsibleFilter.selected{
    background-color: var(--gray-50);
}

.collapsibleFilter.noBorder{
    border-bottom-width: 0px;
}

.cfTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}

.cfFilter{
    width: 98%;
}

.appliedFilter{
    width: 234px;
    
    /* min-height: 81px; */
    height: fit-content;
    border-radius: 8px;
    background: var(--gray-50);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 14px;
}
.appliedFilter.right{
    width: 100%;
}


.appliedFilterValue{
    display: flex;
    padding: 2px 4px 2px 9px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    border: 1px solid var(--gray-300);
    background: var(--base-white, #FFF);
}

.appliedFilterValue.spaced{
    justify-content: space-between;
}

.appliedFilterClose{
    height: 12px;
    width: 12px;
    cursor: pointer;
}

.appliedFilterMultipleValue{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: fit-content;
}


/* -------------------------------------------------------------------------------- */


.showDropdownSelect{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 232px;
    padding: 10px 12px;
    border-radius: 32px;
    border: 1px solid #EAECF0;
    background-color: #FCFCFD;
    cursor: pointer;
}

.showDropdownPopUp{
    box-shadow: 0px 4px 24px 0px #0000001F;
    /* box-shadow: 0px 1px 2px 0px #00000014; */
    width: 254px;
    height: auto;
    position: absolute;
    background-color: white;
    z-index: 20;
    top: 43px;
    left: 50px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.showDropdownOption{
    height: 38px;
    width: 100%;
    padding: 9px 10px;
    cursor: pointer;
    text-align: start;
}

.showDropdownOption.top{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.showDropdownOption.bottom{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


.resultsTable{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: flex-start;
    max-width: 1345px;

}

.tableEle{
    width: 100%;
    table-layout: fixed;
    font-size: 16px;
    text-align: left;
    gap: 300px;
    margin-bottom: 100px;
}


th{
    padding: 0px 20px 0px 0px;
    /* max-width: 400px; */
  }

td{
    padding: 20px 20px 20px 0px;
    /* max-width: 400px; */
  }

  th.shareholders{
    padding-right: 0px;
    padding-left: 20px;
  }

  td.shareholders{
    padding-right: 0px;
    padding-left: 20px;
  }


table tr td:last-child{ /* last-child returns the last instance of the td */
padding-right: 0px;
/* additional styles as required */
}
table tr th:last-child{ /* last-child returns the last instance of the td */
padding-right: 0px;
/* additional styles as required */
}



.tableRow{

    position: relative;
    border-bottom: 1px solid var(--gray-200, #EAECF0);
    height: 72px;
    padding: 16px 20px;
    box-sizing: border-box;
    /* background-color: red; */
}

.tableRow:hover{
    border-bottom: 2px solid var(--gray-200, #EAECF0);
}

.tableRow.premium{
    background-color: #FEFAF5;
}

.premiumCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #FFE6D5;
}

.premiumIcon{
    height: 16px;
    width: 16px;
}

.premiumBar{
    width: 90%;
    height: 13px;
    border-radius: 120px;
    background-color: #FFE6D5;
}

.contactDataRow{
    width: fit-content;
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
}
.contactDataInnerRow{
    display: flex;
    gap: 8px;
    align-items: center;
}
.cdIcon{
    height: 16px;
    width: 16px;
}

.rowSelected{
    z-index: 301;
    background-color: white;
    padding: 15px 10px 15px 15px;
    border-radius: 23px;
}


.loadingDiv{
    display: flex;
    align-items: center;
    gap: 14px;
}


.loadingSpinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #FF0B84;
    border-left: 3px solid #FF0B84;
    border-right: 3px solid #FF0B84;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  



  .searchResultsTopRow{
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .appliedSmartTags{
    display: flex;
    align-items: center;
    gap: 12px;
  }






  .dot-flashing {
    margin-top: 2px;
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: white;
    }
    50%, 100% {  
      background-color: rgba(0, 0, 0, 0.2);
    }
  }


  .noResults{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: 50px;
  }

  .noResultsIcon{
    height: 24px;
    width: 24px;
  }

  .noResultsInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-width: 350px;
  }





.searchBubble.results{
    width: 812px;
    min-height: 456px;
    
    top: -10px;
    left: 80px;
    gap: 38px;
}  


.searchBubble.upgrade{
    width: 343px;
    height: 273px;
    top: 12px;
    overflow: hidden;
    position: relative;
}

.sbrRow{
    display: flex;
    gap: 80px;
    align-items: flex-start;
    width: 100%;
}

.sbrRow.top{
    align-items: center;
}

.sbrLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 35%;
}

.sbrLeft.companyData{
    gap: 32px;
}

.sbrRight{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 42px;
}

.sbrFinancialRow{
    width: 100%;
    padding: 15px 29px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: var(--gray-50, #F9FAFB);
}

.sbrFinancialColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.sbrCompanyData{
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.sbrCompanyIcon{
    height: 20px;
    width: 20px;
}

.sbrCompanyText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}



.sbrShareholders{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 20px;
}

.sbrTitleRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 16px;
}

.sbrTitleRow.noBorder{border-bottom: 0px;}

.sbrTitleRowLeft{
    display: flex;
    align-items: center;
    gap: 12px;
}


.sbrContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
}

.sbrPersonTopRow{
    display: flex;
    align-items: center;
    gap:8px;
}


.fadeBottom{
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0px;
    left: 0px;
    background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 126.32%);
}

.resultsFade{
    position: fixed;
    width: 100%;
    bottom: 0px;
    /* height: 374px; */
    height: 25%;
    min-height: fit-content;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 25.86%), linear-gradient(0deg, #FFF 34.79%, rgba(255, 255, 255, 0.00) 82.06%);
    backdrop-filter: blur(7px);

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.resultsFade.closed{
    height: fit-content;
    padding-bottom: 20px;
}

.notEnoughCreditsIssue{
    margin-top: -40px;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid var(--primary-50, #FFF1F3);
    background: var(--primary-100, #FFE4E8);
}


.searchMultipleGrid{
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    display: flex;
  flex-wrap: wrap;
    gap: 8px;
    max-height: 80px;
    overflow-y: auto;
    overflow-x: hidden;
}


.pagerItem{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    color: var(--gray-600, #475467);
    text-align: center;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.pagerItem.selected{
    background: var(--gray-50, #F9FAFB);
    border-radius: 50%;
}

.pagerItem.noPointer{cursor: default;}

.hideExport{
    position: absolute;
    left: 32px;
    bottom: 32px;
}

.reloadButton{
    color: var(--primary-700);
    font-size: 12px;
    position: absolute;
    right: 20px;
    top: 100px;
}

.showPrevious{
    position: absolute;
    top: 100px;
    left: 26px;
}

@media (max-width: 900px) {

    .disclaimer{
        display: flex;
    }

}