@import './constants/color.css';

.homeScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    gap: 20px;
    z-index: 30;
}

.testBtn{
    background-color: #E1E1E1;
    padding: 5px;
    border-radius:5px;
    margin: 5px;
    font-family: 'InstrumentSans';
}

.searchScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 183px;
    gap: 48px;    
    padding-bottom: 100px;
    z-index: 19;
    height: fit-content;
}

.searchScreen.sep{
    z-index: 21;
}

.searchScreenContainer{
    max-width: 1440px;
    padding: 20px;
    box-sizing: border-box;
    /* overflow-y: auto; */
    gap: 48px;   
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 100px;
    padding-bottom: 300px;
}

.searchScreen.noMargin{
    margin-top: 110px;    
}


.fundIframe{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 10vh;
    width: 100%;
    z-index: 200;
}