@import './color.css';


.alignLeft{text-align: left;}
.alignRight{text-align: right;}

.regular{font-weight: 400;}
.medium{font-weight: 500;}
.semibold{font-weight: 600;}
.bold{font-weight: 700;}



.display-2xl{
    font-family: 'InstrumentSans';
    font-size: 72px;
    line-height: 90px; /* 125% */
    letter-spacing: -1.44px;
}

.display-xl{
    font-family: 'InstrumentSans';
    font-size: 60px;
    line-height: 72px; /* 125% */
    letter-spacing: -1.2px;
}

.display-lg{
    font-family: 'InstrumentSans';
    font-size: 48px;
    line-height: 60px; /* 125% */
    letter-spacing: -0.96px;
}

.display-md{
    font-family: 'Inter';
    font-size: 36px;
    line-height: 44px; /* 125% */
    letter-spacing: -0.72px;
}

.display-sm{
    font-family: 'InstrumentSans';
    font-size: 30px;
    line-height: 38px; /* 125% */
}

.display-xs{
    font-family: 'InstrumentSans';
    font-size: 24px;
    line-height: 32px; /* 125% */
}

.text-xl{
    font-family: 'InstrumentSans';
    font-size: 20px;
    line-height: 30px; /* 125% */
}

.text-lg{
    font-family: 'InstrumentSans';
    font-size: 18px;
    line-height: 28px; /* 125% */
}

.text-md{
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px; /* 125% */
}

.text-sm{
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px; /* 125% */
}

.text-xs{
    font-family: 'Inter';
    font-size: 12px;
    line-height: 18px; /* 125% */
}


.baseWhite{color: var(--base-white);}
.success-700{ color: var(--success-700); }
.primary-700{ color: var(--primary-700); }
.blue-700{ color: var(--blue-700); }

.error-500{ color: var(--error-500); }

.gray-200{ color: var(--gray-200); }
.gray-300{ color: var(--gray-300); }
.gray-400{ color: var(--gray-400); }
.gray-500{ color: var(--gray-500); }
.gray-600{ color: var(--gray-600); }
.gray-700{ color: var(--gray-700); }
.gray-900{ color: var(--gray-900); }

.amber{ color: #ffbf00;}


.oneLine{
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 150px;
}

.twoLine{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
}


.breakword{
    overflow-wrap: break-word;
    max-width: 150px;
    text-align: left;
}