.App {
  text-align: center;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  background-color:white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.underline{
  text-decoration: underline;
  cursor: pointer;
}

.underline-red{
  text-decoration: solid underline var(--primary-500) ;
}

.pointer{cursor: pointer;}

.routes{
  height: 100%;
  background-color: #61dafb;
}

.bottomGraphic{
  
  width: 100%;
  max-height: 302px;
  position: absolute;
  bottom: 0;
}

.fullScreen{
  width: 100%;
  display: flex;
  justify-content: center;
}

.container{
  max-width: 1216px;
  
}

.tint{

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #3E363F80;

  display: flex;
  justify-content: center;
  align-items: center;
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.loader{
  margin-top: -100px;
  margin-bottom: -60px;
}


.loader2{
  margin-top: -140px;
  margin-bottom: -140px;
}


.successToast{

  position: fixed;
  bottom: 40px;
  left: 24px;
  z-index: 500;

  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
background: var(--gray-900, #101828);
/* Shadow/lg */
box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  

}

.successToast.hidden{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 5s, opacity 5s linear;

}

.successToastIcon{
  width: 30px;
  height: 30px;
}


.aboveAll{
  z-index: 2000;
}


.aboveBottom{
  z-index: 200;
  padding-bottom: 300px;
}

.poweredByBC{

  position: fixed;
  bottom: 16px;
  left: 66px;

  height: 32px;
  width: 109px;
  background-color: white;
  z-index: 20;

  border-radius: 32px;
  padding: 4px, 14px, 5px, 10px;
  box-sizing: border-box;

  box-shadow: 0px 4px 12px 0px #00000014;
  box-shadow: 0px 1px 2px 0px #00000014;
 
  display: flex;
  align-items: center;
  justify-content: center;

}

.poweredByBCImg{
  width: 91px;
  height: 22px;
}